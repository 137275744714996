import React, { useContext, useState, useEffect } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, PageProps } from 'gatsby'
import { theme } from 'src/theme/ThemeProvider'
import { IPageContext } from '@/types/pages'
import ThemeTypeContext from '@/components/themeTypeContext'
import '../styles/404.css'
import { Grid, Typography, Box } from '@mui/material'
import { Oval } from 'react-loader-spinner'
import {
  Strapi__Component_Meta_Metadata,
  Strapi_Global,
  JewelQuery,
  Strapi__Component_Links_Button,
  Strapi__Media,
} from 'gatsby-graphql'
import Button from '../components/elements/button'
import { css } from '@emotion/css'
import RichText from '@/components/elements/rich-text'
import { Icon } from '@/components/elements/icon'
import Image from '@/components/image'

const Jewel: React.FC<PageProps<JewelQuery>> = (props) => {
  const { setTheme } = useContext(ThemeTypeContext)
  setTheme('core')
  const data = props?.data
  const pageContext = props?.pageContext as IPageContext

  const metaData = data?.strapiJewel?.MetaData as Strapi__Component_Meta_Metadata
  const global = data?.strapiGlobal as Strapi_Global

  const HeroData = data?.strapiJewel?.JewelHero
  const HighlightData = data?.strapiJewel?.Highlights
  const JewelTrialButton = data?.strapiJewel?.JewelTrialButton
  const BannerImage = HeroData?.JewelBannerImage

  const [spinner, setSpinner] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setSpinner(false)
    }, 500)
  }, [])

  return (
    <>
      <SEO metaData={metaData} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, ...metaData }} theme={theme}>
        {HeroData?.Heading && (
          <Grid container sx={{ paddingBottom: { xs: '28px', md: '65px' } }}>
            <Grid item xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  maxWidth: { xs: '600px' },
                  margin: 'auto',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 30, md: 40 },
                    fontWeight: 600,
                    lineHeight: { xs: '40px', md: '44px' },
                    letterSpacing: '-0.833px',
                  }}
                >
                  {HeroData?.Heading}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          sx={{
            marginBottom: { xs: '50px', md: '95px' },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'space-between', lg: 'space-between' },
            alignItems: { xs: 'flex-start', md: 'flex-start' },
            gap: { xs: '18px', md: '0' },
          }}
          className={css`
            @media (max-width: 720px) {
              flex-direction: column-reverse !important;
              margin-bottom: 10px !important;
            }
          `}
        >
          <Grid
            item
            md={6}
            xs={12}
            sx={{ marginTop: { md: '0', xs: 0 }, width: '100%', maxWidth: { xs: '327px', md: '450px', lg: '561px' } }}
            className={css`
              @media (max-width: 720px) {
                max-width: 100% !important;
              }
            `}
          >
            <Grid container>
              {HeroData?.Title && (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: { xs: '30px', md: '40px' },
                      lineHeight: { xs: '40px', md: '40px' },
                      fontWeight: '700',
                      textAlign: { md: 'left', xs: 'left' },
                    }}
                  >
                    {HeroData?.Title}
                  </Typography>
                </Grid>
              )}
              {HeroData?.Description && (
                <Grid item xs={12} sx={{ minHeight: '200px' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      fontSize: { lg: '20px', xs: '14px' },
                      lineHeight: { lg: '30px', xs: '18px' },
                      position: 'relative',
                      fontWeight: '400',
                      maxWidth: '550px',
                    }}
                    className={css`
                      @media (max-width: 720px) {
                        max-width: 100% !important;
                      }
                    `}
                  >
                    <RichText markdown={HeroData?.Description?.data?.Description} />
                  </Typography>
                </Grid>
              )}

              {HeroData?.JewelButton && (
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    paddingX: '1%',
                    paddingBottom: { xs: '40px', md: '4px' },
                    marginTop: '13px',
                    display: { xs: 'flex' },
                    justifyContent: { xs: 'flex-start', md: 'flex-start' },
                  }}
                  className={css`
                    @media (max-width: 720px) {
                      justify-content: center !important;
                    }
                  `}
                >
                  <Box
                    className={css`
                      @media (max-width: 720px) {
                        width: 100% !important;
                      }
                    `}
                  >
                    <Button
                      sx={{
                        width: { xs: '100%' },
                        paddingY: '12px',
                        minWidth: '180px',
                        maxWidth: { xs: '100%', md: '100%' },
                      }}
                      className={css`
                        @media (max-width: 720px) {
                          max-width: 100% !important;
                        }
                      `}
                      button={HeroData?.JewelButton as Strapi__Component_Links_Button}
                      color="secondary"
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>

          {HeroData?.MediaSection?.VideoUrl ? (
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                marginTop: { md: '0px', xs: '50px', lg: '50px' },
                width: '100%',
                minWidth: { md: 'auto', lg: '550px' },
                maxWidth: { xs: '327px', md: '450px', lg: '550px' },
                height: { xs: '241px', md: '349px', lg: '349px' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: { xs: 'center', md: `${spinner ? 'center' : 'flex-start'}` },
              }}
              className={css`
                @media (max-width: 720px) {
                  max-width: 100% !important;
                  margin-top: 0px !important;
                }
              `}
            >
              {spinner ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Oval color="#ffffff" />
                </Box>
              ) : (
                <>
                  {HeroData?.MediaSection?.VideoUrl && (
                    <iframe
                      className={css`
                        width: 100%;
                        height: 100%;
                        @media (max-width: 991px) {
                          max-width: 100%;
                          aspect-ratio: 16/9 !important;
                          max-height: 241px;
                        }
                        @media (max-width: 720px) {
                          max-width: 100%;
                          aspect-ratio: 16/9 !important;
                          max-height: 241px;
                        }
                      `}
                      src={HeroData?.MediaSection?.VideoUrl}
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                      title="The Jewel and Meta Signals"
                    ></iframe>
                  )}
                </>
              )}
            </Grid>
          ) : (
            ''
          )}
        </Grid>

        <Grid container sx={{ paddingBottom: '24px', marginTop: { xs: '0', md: '4px' } }}>
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              sx={{
                paddingBottom: { xs: '24px' },
                maxWidth: { xs: '499px', md: '650px' },
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              {HighlightData?.Title && (
                <Typography
                  sx={{
                    fontSize: { xs: 30, md: 40 },
                    fontWeight: 700,
                    lineHeight: '40px',
                    paddingBottom: { xs: '12px', md: '15px' },
                  }}
                >
                  {HighlightData?.Title}
                </Typography>
              )}
              {HighlightData?.SubTitle && (
                <Typography
                  sx={{
                    fontSize: { xs: 20, md: 20 },
                    lineHeight: '30px',

                    fontWeight: 400,
                    paddingBottom: { xs: '16px', md: '23px' },
                    textAlign: 'center',
                    marginX: 'auto',
                  }}
                  className={css`
                    @media (max-width: 720px) {
                      font-size: 18px !important;
                    }
                    @media (max-width: 479px) {
                      max-width: 288px !important;
                    }
                  `}
                >
                  {HighlightData?.SubTitle}
                </Typography>
              )}

              {HighlightData &&
                HighlightData?.Highlight.map((item: any, index: any) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto',
                      gap: '10px',
                      alignItems: { xs: 'flex-start', md: 'flex-start' },
                      justifyContent: 'flex-start',
                      borderBottom: '1px solid rgba(255, 255, 255, 0.25)',
                      boxShadow: '0px 0px 0px 0px rgba(255, 255, 255, 0.25)',
                      padding: { xs: '4px 8px' },
                      maxWidth: { xs: '499px', md: '632px' },
                    }}
                    className={css`
                      @media (max-width: 479px) {
                        max-width: 320px !important;
                        margin: 0px auto !important;
                      }
                      @media (max-width: 720px) {
                        padding: 8px !important;
                      }
                    `}
                  >
                    <Box
                      style={{
                        height: '20px',
                        maxWidth: '20px',
                        marginTop: '20px',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        jusetifyContent: 'center',
                        background: 'white',
                        border: '1px solid #fff',
                        borderRadius: '50%',
                      }}
                      className={css`
                        @media (max-width: 940px) {
                          margin-top: 1rem;
                        }
                        @media (max-width: 720px) {
                          margin-top: 5px !important;
                        }
                      `}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="white">
                        <g clip-path="url(#clip0_168_35)">
                          <path
                            d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.0043329 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34878 18.9424 4.80704 17.0677 2.93234C15.193 1.05765 12.6512 0.00308769 10 0ZM15.7725 6.83333L10.0683 14.5742C10.001 14.6635 9.91653 14.7386 9.81987 14.7949C9.72321 14.8513 9.61629 14.8878 9.50536 14.9024C9.39442 14.917 9.2817 14.9093 9.17376 14.8799C9.06581 14.8504 8.96482 14.7997 8.87667 14.7308L4.80334 11.4742C4.71787 11.4058 4.64671 11.3212 4.59392 11.2253C4.54113 11.1294 4.50775 11.0241 4.49568 10.9153C4.4713 10.6955 4.5352 10.4751 4.67334 10.3025C4.81147 10.1299 5.01252 10.0192 5.23225 9.99484C5.45198 9.97046 5.67239 10.0344 5.845 10.1725L9.24167 12.89L14.4308 5.8475C14.4933 5.75376 14.5741 5.67365 14.6684 5.61203C14.7627 5.5504 14.8686 5.50854 14.9795 5.48897C15.0904 5.46939 15.2042 5.47251 15.3139 5.49814C15.4236 5.52377 15.527 5.57138 15.6177 5.63808C15.7085 5.70479 15.7848 5.7892 15.8421 5.88623C15.8993 5.98326 15.9363 6.09089 15.9507 6.20261C15.9652 6.31433 15.9569 6.42782 15.9262 6.53623C15.8956 6.64464 15.8433 6.74571 15.7725 6.83333Z"
                            fill="#419865"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_168_35">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Box>

                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '24px',
                        textAlign: 'left',
                        padding: '16px 0',
                      }}
                      className={css`
                        @media (max-width: 720px) {
                          margin: 0 !important;
                          padding: 0 !important;
                        }
                      `}
                    >
                      {item?.Highlight}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: { xs: 'block', md: 'block' },
            width: '100vw',
            height: { xs: '160px', md: '311px', lg: '311px', xl: 'max-content' },
            marginLeft: 'calc((-100vw + 100%)/2)',
            overflow: 'hidden',
          }}
          className={css`
            @media screen and (min-width: 1535px) and (max-width: 1820px) {
              height: 311px !important;
            }
            @media (max-width: 479px) {
              object-fit: contain !important;
              height: 124px !important;
            }
          `}
        >
          <Image
            media={BannerImage as Strapi__Media}
            style={{
              width: '100vw',
              objectFit: 'cover',
              maxHeight: 'max-content',
              marginLeft: 'calc((-100vw + 100%)/2)',
            }}
            className={css`
              @media (max-width: 479px) {
                object-position: left;
                height: 124px !important;
              }
            `}
          />
        </Box>

        {JewelTrialButton && (
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: { xs: '72px', md: '54px' },
              marginTop: { xs: '52px', md: '67px' },
              marginX: 'auto',
              display: 'flex',
            }}
          >
            <Box
              sx={{ marginX: 'auto' }}
              className={css`
                @media (max-width: 720px) {
                  width: 100% !important;
                }
              `}
            >
              <Button
                sx={{
                  width: { xs: '100%' },
                  paddingY: '12px',
                  minWidth: '180px',
                  maxWidth: { xs: '100%', sm: 'max-content' },
                  marginX: 'auto',
                }}
                className={css`
                  @media (max-width: 720px) {
                    max-width: 100% !important;
                  }
                `}
                button={JewelTrialButton as Strapi__Component_Links_Button}
              />
            </Box>
          </Grid>
        )}
      </Layout>
    </>
  )
}

export default Jewel
export const query = graphql`
  query Jewel($locale: String) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiJewel(locale: { eq: $locale }) {
      MetaData {
        MetaTitle
        MetaDescription
      }
      JewelTrialButton {
        newTab
        url
        type
        icon
        text
      }
      Highlights {
        Title
        SubTitle
        Highlight {
          Highlight
        }
      }
      JewelHero {
        Heading
        Title
        Description {
          data {
            Description
          }
        }
        JewelButton {
          newTab
          url
          type
          icon
          text
        }
        MediaSection {
          VideoUrl
        }
        JewelBannerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            publicURL
          }
        }
      }
    }
  }
`
